<template>
  <v-dialog v-model="dialogColab" max-width="700" persistent>
    <div class="table-empresas">
      <v-simple-table>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Membros Colaboradores</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon color="red">mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:default>
          <thead style="background-color: #d6d6d6">
            <tr>
              <th class="text-left">Nome</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in realizadoEquipe" :key="item.colaborador">
              <td>{{ item.colaborador | TitleCase }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-dialog>
</template>
<script>
import comissao from "@/services/http/comissaoService";
export default {
  props: {
    item: {
      type: String || Number
    },
    dialogColab: {
      type: Boolean
    }
  },
  data() {
    return {
      realizadoEquipe: [],
      loading: false
    };
  },
  watch: {
    item(value) {
      this.fetchList(value);
    }
  },
  methods: {
    close() {
      this.$emit("closeColab");
    },
    async fetchList(idEquipe) {
      let {
        data: { data }
      } = await comissao()
        .colaboradorEquipe()
        .show({
          per_page: -1,
          id_equipe: idEquipe,
          ativo: "S"
        });
      this.realizadoEquipe = data;
    }
  }
};
</script>
