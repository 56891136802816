<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Meta" : "Cadastrar Meta" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                prepend-inner-icon="mdi-file-table-box-multiple-outline"
                clearable
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                service="sistemaService.tipoBandeiras"
                :filters="{ id_band: '2,4' }"
                v-model="form.id_band"
                :isCompany="true"
                @change="handleFilter()"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                label="Empresa"
                v-model="form.id_empresa"
                service="sistemaService.empresa"
                item-text="apelido"
                item-value="id_empresa"
                prepend-inner-icon="mdi-domain"
                :rules="[rules.required]"
                clearable
                :isCompany="true"
                :multiple="false"
                :disabled="locked"
                :filters="{ calcula_comissao: 'S' }"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                prepend-inner-icon="mdi-account-group"
                clearable
                single-line
                label="Equipe"
                item-text="nome_equipe"
                item-value="id_equipe"
                service="comissaoService.tipoEquipe"
                :filters="{
                  id_empresa: form.id_empresa,
                  ativo: 'S'
                }"
                v-model="form.id_equipe"
                :disabled="locked"
              />
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <BaseSelect
                label="Departamento"
                v-model="form.id_depto"
                service="comissaoService.departamentos"
                item-text="departamento"
                item-value="id_depto"
                prepend-inner-icon="mdi-toolbox"
                :rules="[rules.required]"
                clearable
                :multiple="false"
                :disabled="locked"
              />
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <BaseSelect
                label="Indicador"
                v-model="form.id_indicador"
                service="sistemaService.indicadores"
                item-text="indicador"
                item-value="id_indicador"
                prepend-inner-icon="mdi-finance"
                :rules="[rules.required]"
                :filters="{ ativo: 'S' }"
                clearable
                :hasCode="true"
                :multiple="false"
                :disabled="locked"
              />
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <BaseSelect
                label="Tipo Evento"
                v-model="form.id_tipo_evento"
                service="comissaoService.tipoEvento"
                item-text="tipo_evento"
                item-value="id_tipo_evento"
                prepend-inner-icon="mdi-calendar-text"
                :rules="[rules.required]"
                :filters="{ id_tipo_evento: 'C' }"
                clearable
                :multiple="false"
                @change="fetchTipoFechamento()"
                :disabled="locked"
              />
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <BaseSelect
                label="Tipo Dado"
                v-model="form.id_tipo_dado"
                service="comissaoService.tipoDados"
                item-text="descricao"
                item-value="id_tipo_dado"
                prepend-inner-icon="mdi-calendar-end"
                :rules="[rules.required]"
                :filters="{ status: 'S' }"
                clearable
                :multiple="false"
                @change="fetchTipoFechamento()"
                :disabled="locked"
              />
            </v-col>
            <v-col class="pt-2" cols="12" md="3" lg="3">
              <DatePickerMonth
                @date="assignDateFilter($event), fetchTipoFechamento()"
              />
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-autocomplete
                label="Tipo Fechamento"
                v-model="form.id_tipo_fechamento"
                :items="selects.tipoFechamento.itens"
                :value-comparator="comparator"
                item-text="textTipoFechamento"
                item-value="id_tipo_fechamento"
                :rules="[rules.required]"
                clearable
                chips
                :multiple="false"
                :disabled="
                  form.id_tipo_evento == undefined ||
                    form.id_tipo_dado == undefined ||
                    locked
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-text-field
                label="Meta QTD"
                :rules="[rules.required]"
                v-model="form.meta_qtde"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <vuetify-money
                label="Meta Valor"
                :rules="[rules.required]"
                v-model="form.meta_valor"
                valueWhenIsEmpty="0"
                :options="optionsVuetifyMoney"
              >
              </vuetify-money>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-text-field
                label="Meta Perc"
                :rules="[rules.required]"
                v-model="form.meta_perc"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import comparator from "@/mixins/comparatorValue";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";
export default {
  name: "ModalCadMetaEquipe",

  mixins: [rules, comparator],

  components: {
    BaseSelect,
    DatePickerMonth
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    },
    editMetaEquipe: {
      type: Object
    }
  },

  data() {
    return {
      form: {},
      filters: {},
      locked: false,
      selects: {
        tipoFechamento: {
          itens: []
        }
      },
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      }
    };
  },

  watch: {
    editMetaEquipe(value) {
      this.form = value;
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  },

  methods: {
    async send() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .metaEquipe()
          .store(this.form, {
            notification: true,
            message: "Meta cadastrada com sucesso!"
          });
      }
    },

    async put() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .metaEquipe(this.form.id_meta_equipe)
          .update(this.form, {
            notification: true,
            message: "Meta editada com sucesso!"
          });
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },

    async fetchTipoFechamento() {
      let {
        data: { data }
      } = await comissao()
        .tipoFechamento()
        .show({
          per_page: -1,
          id_tipo_dado: this.form.id_tipo_dado,
          id_tipo_evento: this.form.id_tipo_evento,
          ...this.filters
        });

      this.selects.tipoFechamento.itens = data.map(item => {
        item["textTipoFechamento"] = `${this.converteDate(
          item.data_inicio
        )} - ${this.converteDate(item.data_final)}`;
        return item;
      });
    },

    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
    },

    converteDate(date) {
      //in: 2020-06-30 00:00:00
      //out: 30/06/2020
      return date
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("-")
        .replaceAll("-", "/");
    }
  },

  mounted() {
    this.fetchTipoFechamento();
  }
};
</script>

<style></style>
